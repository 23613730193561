<template>
  <div class="layout">
    <Header :showSwitchLang="true" />
    <Banner @btnClick="showDialog" />
    <div
      class="toggle-tab"
      ref="tabWrapper"
    >
      <div class="view-container">
        <ToggleTab
          ref="toggleTab"
          :scroll="scroll"
        />
      </div>
    </div>
    <router-view />
    <div class="open-card" :class="{en: lang == 'en'}">
      <div class="view-container">
        <OpenCard
          @btnClick="imgClick"
          :showIcon="false"
        />
      </div>
    </div>
    <Footer />
    <div
      v-if="isMobile() && showFloatButton"
      class="float-button"
      @click="showDialog"
    >
      <img
        src="~@/assets/images/icon/icon-float-btn.png"
        alt="float-btn"
      >
      {{ $t('开户申请') }}
    </div>
    <FormDialog
      v-if="isMobile()"
      pageType="6"
      countryCode="852"
      :visible.sync="showForm"
    />
  </div>
</template>

<script>
import Banner from '@/pages/GAT/components/Banner'
import ToggleTab from '@/pages/GAT/components/ToggleTab'
import FormDialog from '@/pages/AW/components/FormDialog'

import { getRect, getScrollTop } from '@/utils/viewport'
import { isMobile } from '@/utils/utils'
import { mapGetters, mapMutations } from 'vuex'
import mixins from '@/mixins/layout.mixins'

export default {
  mixins: [mixins],
  components: {
    Banner,
    ToggleTab,
    FormDialog
  },
  data () {
    return {
      showForm: false,
      isMobile,
      scroll: false,
      showFloatButton: false
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  created () {
    this.setLanguage()
  },
  mounted () {
    // 滚动吸顶
    const tabWrapper = this.$refs.tabWrapper
    const { top } = getRect(tabWrapper)
    document.addEventListener('scroll', () => {
      const scrollTop = getScrollTop()
      const containsScroll = tabWrapper.classList.contains('scroll')
      if (isMobile()) {
        if (scrollTop > 700) {
          this.showFloatButton = true
        } else {
          this.showFloatButton = false
        }
      }
      if (scrollTop >= top) {
        if (!containsScroll) {
          this.scroll = true
          tabWrapper.classList.add('scroll')
          // 重新计算tab偏移
          this.$refs.toggleTab.setSlider()
        }
      } else {
        if (containsScroll) {
          this.scroll = false
          tabWrapper.classList.remove('scroll')
          // 重新计算tab偏移
          this.$refs.toggleTab.setSlider()
        }
      }
    })
  },
  methods: {
    ...mapMutations('app', ['SET_ALL'])
  }
}
</script>

<style scoped lang="less">
.layout {
  position: relative;
  .toggle-tab {
    position: static;
    width: 100%;
    margin-top: -39px;
    margin-bottom: -39px;
    transition: position 0.5s ease;
    &.scroll {
      position: fixed;
      top: 0;
      z-index: 2000;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .open-card {
    margin-top: -133px;
    margin-bottom: -90px;
    &.en{
      margin-top: -155px;
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .layout {
    .toggle-tab {
      display: none;
    }
    .open-card {
      margin-top: -44px;
      margin-bottom: -40px;
      &.en {
        margin-top: -68px;
        margin-bottom: -44px;
      }
    }
    .float-button {
      position: fixed;
      right: 20px;
      top: 90px;
      width: 60px;
      height: 60px;
      padding: 10px 5px;
      background: linear-gradient(90deg, #ffbb00 0%, #ff492d 100%);
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 14px;
      border-radius: 4px;
      img {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto;
      }
    }
  }
}
</style>
