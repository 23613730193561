<template>
  <div class="banner">
    <div class="view-container">
      <div class="banner-wrapper" :class="{en: lang == 'en'}">
        <BannerText />
        <div
          class="btn"
          @click="handleSubmit"
        >
          <span>{{ $t(btnText) }}</span>
          <img
            src="@/assets/images/banner/upper-right-arrow.png"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BannerText from './BannerText'
export default {
  components: { BannerText },
  props: {
    btnText: {
      type: String,
      default: '开户申请'
    }
  },
  methods: {
    handleSubmit () {
      this.$emit('btnClick')
    }

  },
  computed: {
    ...mapGetters(['lang'])
  }
}
</script>

<style scoped lang="less">
.banner {
  height: 680px;
  background-image: url('~assets/images/banner/bg.png');
  background-size: cover;
  .banner-wrapper {
    // display: flex;
    // justify-content: space-between;
    color: #fff;

    padding-top: 180px;
    &.en {
      padding-top: 160px;
    }

    .btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240px;
      margin-top: 30px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: linear-gradient(270deg, #ffbb00 0%, #ff492d 100%);
      border-radius: 4px;
      user-select: none;
      cursor: pointer;
      animation-name: flashover;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      span {
        font-size: 20px;
        color: #ffffff;
      }
      > img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
    @keyframes flashover {
      0% {
        transform: scale(0.92);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.92);
      }
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .banner {
    height: 375px;
    background-image: url('~assets/images/banner/m_bg.png');
    .banner-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 84px;
      &.en {
        padding-top: 80px;
      }
      .btn {
        width: 128px;
        color: #ffffff;
        margin-top: 20px;
        span {
          font-size: 16px;
          color: #ffffff;
        }
        > img {
          width: 16px;
          height: 16px;
          margin-left: 10px;
        }
      }
      .openAccount-btn {
        padding: 8px 22px 8px 8px;
        border-radius: 4px;
        font-size: 10px;
        line-height: 14px;
        &:before {
          position: absolute;
          top: 10px;
          right: 8px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
</style>
