<template>
  <div class="content">
    <p class="name-en" v-if="lang != 'en'">CB International Bank</p>
    <template v-if="lang == 'zh-CN'">
      <p class="name-cn">富港银行</p>
      <p class="tip1">专注全球贸易/电商收付款/国际投融资</p>
      <p class="tip2">离岸账户/美国银行账户</p>
      <p class="tip3">支持【全球】国家地区公司开立 <span>企业</span> 账户</p>
    </template>
    <template v-else-if="lang == 'zh-tw'">
      <p class="name-cn">富港銀行</p>
      <p class="tip1">專註全球貿易/電商收付款/國際投融資</p>
      <p class="tip2">離岸賬戶/美國銀行賬戶</p>
      <p class="tip3">支持【全球】國家地區公司開立 <span>企業</span> 賬戶</p>
    </template>
    <template v-else>
      <p class="name-cn en">CB International Bank</p>
      <p class="tip1">
        Focused on global trade/e-commerce payment processing/international investment and financing, offshore accounts/U.S. bank accounts.
      </p>
      <p class="tip2"></p>
      <p class="tip3 en">
        Supports companies from global countries and regions in opening <span>business</span> accounts.
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['lang'])
  }
}
</script>

<style scoped lang="less">
.content {
  // max-width: 642px;
  padding-top: 40px;
  padding-right: 480px;
  .name-en {
    margin-bottom: 20px;
    font-size: 23px;
    letter-spacing: 3px;
  }
  .name-cn {
    margin-bottom: 50px;
    font-size: 64px;
    line-height: 64px;
    letter-spacing: 14px;
    &.en {
      letter-spacing: normal;
    }
  }
  .tip1 {
    font-weight: 300;
    line-height: 32px;
  }
  .tip2 {
    margin-top: 18px;
    font-weight: 300;
  }
  .tip3 {
    margin-top: 30px;
    padding: 9px 80px 9px 20px;
    line-height: 42px;
    background: linear-gradient(
      90deg,
      rgba(255, 153, 29, 0.53) 0%,
      rgba(216, 216, 216, 0) 100%
    );
    span {
      font-size: 30px;
      color: #ffb901;
      line-height: 42px;
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .content {
    max-width: 100%;
    padding-top: 0;
    padding-right: 0;
    font-size: 14px;
    .name-en {
      margin-bottom: 5px;
      font-size: 10px;
      letter-spacing: 1px;
    }
    .name-cn {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 12px;
      &.en{
        margin-bottom: 10px;
      }
    }
    .tip1 {
    }
    .tip2 {
      margin-top: 10px;
    }
    .tip3 {
      margin-top: 22px;
      line-height: 20px;
      padding-right: 20px;
      &.en{
        margin-top: 15px;
      }
      span {
        font-size: 18px;
        color: #ffb901;
        line-height: 25px;
      }
    }
  }
}
</style>
